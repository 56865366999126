/**
 * @format
 */
import React from 'react';
import {
  BrowserRouter as Router,
  Link,
  Route,
  Switch,
  useLocation,
} from 'react-router-dom';
import {Alert, Button, Card, Form, Input, Space, Typography} from 'antd';
import styles from './App.module.less';
import {SingleColumnLayout} from '@cloudbridge/components';
import queryString from 'query-string';

var base64 = require('base-64');

const {Text} = Typography;

const routes = {
  login: '/auth/login',
  signup: '/auth/signup',
  verify: '/auth/verify',
  logout: '/auth/logout',
  forgot_password: '/auth/forgot-password',
  login_auth: '/auth/login-auth',
  signup_auth: '/auth/signup-auth',
  verify_auth: '/auth/verify-auth',
  forgot_password_auth: '/auth/forgot-password-auth',
};

// eslint-disable-next-line no-useless-escape
const passwordRegex = new RegExp('^[a-zA-Z0-9 ~!@#$%^&*_+=-]{12,}$');
const passwordMinLen = 12;
const defaultForgotPasswordText =
  'Enter the email address you used to create your account ' +
  'to receive instructions on how to reset your password.';

type FormState = {
  username: string;
  password: string;
  code: string;
  token: string;
};

function FormAction(
  path: string,
  setError: React.Dispatch<React.SetStateAction<string | undefined>>,
  setSubmitting: React.Dispatch<React.SetStateAction<boolean>>,
  setForgotPasswordText?: React.Dispatch<React.SetStateAction<string>>,
) {
  return async (values: FormState) => {
    setSubmitting(true);
    try {
      const response = await fetch(path, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      });
      if (path === routes.forgot_password_auth && setForgotPasswordText) {
        if (response.ok) {
          setForgotPasswordText(
            'Instructions for resetting your password have ' +
              'been sent to your account email address.' +
              'Please check your email to continue.',
          );
        } else {
          setForgotPasswordText(
            'Please reach out to your account administrator ' +
              'to request a password reset.',
          );
        }
        setSubmitting(false);
      } else if (response.ok) {
        // We are now logged in. Reloading the page will make the backend redirect to the target
        window.location.reload();
      } else if (response.status === 422) {
        setError('The invitation code does not match the record.');
      } else if (response.status >= 400 && response.status < 500) {
        const body = await response.json();
        const message = body.message || 'Unauthorised';
        setError(message);
      } else if (response.status >= 500 && response.status < 600) {
        setError('The service is not reachable at the moment');
      } else {
        setError('Unexpected response from the server');
      }
      setSubmitting(false);
    } catch {
      setError('The service is not reachable at the moment');
      setSubmitting(false);
    }
  };
}

function ForgotPasswordForm() {
  const [error, setError] = React.useState<string | undefined>(undefined);
  const [submitting, setSubmitting] = React.useState(false);
  const [forgotPasswordText, setForgotPasswordText] = React.useState<string>(
    defaultForgotPasswordText,
  );

  return (
    <Form<FormState>
      size={'large'}
      name="login"
      initialValues={{}}
      onFinish={FormAction(
        routes.forgot_password_auth,
        setError,
        setSubmitting,
        setForgotPasswordText,
      )}
    >
      {forgotPasswordText === defaultForgotPasswordText && (
        <Form.Item
          name="username"
          rules={[{required: true, message: 'Please input your email'}]}
        >
          <Input placeholder="Email" />
        </Form.Item>
      )}

      <div style={{paddingBottom: 5}}>{forgotPasswordText}</div>

      {forgotPasswordText === defaultForgotPasswordText && (
        <Form.Item>
          <Button
            type="primary"
            block={true}
            htmlType="submit"
            loading={submitting}
          >
            Submit
          </Button>
        </Form.Item>
      )}
    </Form>
  );
}

function ForgotPasswordCard() {
  return (
    <Card className={styles.login_card}>
      <div className={styles.login_card_row}>
        <Space
          className={styles.login_card_column}
          direction="vertical"
          size="large"
        >
          <Typography.Title level={4}>Reset Password</Typography.Title>
          <ForgotPasswordForm />
          <Link
            to={`${routes.login}/${window.location.search}${window.location.hash}`}
          >
            Back to log in
          </Link>
        </Space>
      </div>
    </Card>
  );
}

function LoginForm() {
  const [error, setError] = React.useState<string | undefined>(undefined);
  const [submitting, setSubmitting] = React.useState(false);
  const {search} = useLocation();
  const params = queryString.parse(search);

  var email = undefined;
  if (params && params.code) {
    try {
      email = JSON.parse(base64.decode(params.code)).email;
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <Form<FormState>
      size={'large'}
      name="login"
      initialValues={{username: email}}
      onFinish={FormAction(routes.login_auth, setError, setSubmitting)}
    >
      <Form.Item
        name="username"
        rules={[{required: true, message: 'Please input your email'}]}
      >
        <Input placeholder="Email" />
      </Form.Item>

      <Form.Item
        name="password"
        rules={[{required: true, message: 'Please input your password'}]}
      >
        <Input.Password placeholder="Password" />
      </Form.Item>

      <Form.Item>
        <Button
          type="primary"
          block={true}
          htmlType="submit"
          loading={submitting}
        >
          Log In
        </Button>
      </Form.Item>
      {error && <Alert message="Error" description={error} type="error" />}
    </Form>
  );
}

async function showSignupButton(
  setShowSignup: React.Dispatch<React.SetStateAction<string | undefined>>,
) {
  let url = '/tenant';
  try {
    let res = await fetch(url);
    if (res.ok) {
      let data = await res.json();
      setShowSignup(String(data.multitenantEnabled));
    }
  } catch (error) {
    console.log(error);
  }
}

function LoginCard() {
  const [showSignup, setShowSignup] = React.useState<string | undefined>(
    undefined,
  );
  showSignupButton(setShowSignup);

  return (
    <Card className={styles.login_card}>
      <div className={styles.login_card_row}>
        <Space
          className={styles.login_card_column}
          direction="vertical"
          size="large"
        >
          <Typography.Title level={4}>Log In</Typography.Title>
          <LoginForm />
          {showSignup === 'true' && (
            <Link
              to={`${routes.forgot_password}/${window.location.search}${window.location.hash}`}
            >
              Forgot Password?
            </Link>
          )}
        </Space>
      </div>
    </Card>
  );
}

function VerifyCard() {
  const {search} = useLocation();
  const params = queryString.parse(search);
  const headerText =
    params && params.et === 'inv'
      ? 'Create your account'
      : 'Update your password';
  return (
    <Card className={styles.login_card}>
      <div className={styles.login_card_row}>
        <Space
          className={styles.login_card_column}
          direction="vertical"
          size="large"
        >
          <Typography.Title level={4}>{headerText}</Typography.Title>
          <VerifyForm />
        </Space>
      </div>
    </Card>
  );
}

function VerifyForm() {
  const [error, setError] = React.useState<string | undefined>(undefined);
  const [submitting, setSubmitting] = React.useState(false);

  const {search} = useLocation();
  const params = queryString.parse(search);
  const inputToken = params && params.token;
  const invite = params && params.et === 'inv' ? true : false;
  const submitText = invite ? 'Create Account' : 'Submit';

  return (
    <Form<FormState>
      size={'large'}
      name="signup"
      initialValues={{token: inputToken}}
      onFinish={FormAction(routes.verify_auth, setError, setSubmitting)}
    >
      <Form.Item
        name="username"
        rules={[
          {
            required: true,
            message: 'Please input your email',
          },
          {
            type: 'email',
            message: 'The input is not valid email!',
          },
        ]}
      >
        <Input placeholder="Email" />
      </Form.Item>

      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: 'Please input your password',
          },
          {
            min: passwordMinLen,
            message: 'Password must have a minimum length of 12',
          },
          {
            pattern: passwordRegex,
            message:
              'Only Alphanumeric characters and ~!@#$%^&*_+=- symbols can be used in password',
          },
        ]}
        hasFeedback
      >
        <Input.Password placeholder="New Password" />
      </Form.Item>

      <Form.Item
        name="confirm"
        dependencies={['password']}
        hasFeedback
        rules={[
          {
            required: true,
            message: 'Please confirm your password',
          },
          ({getFieldValue}) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }

              return Promise.reject(
                new Error('The two passwords that you entered do not match!'),
              );
            },
          }),
        ]}
      >
        <Input.Password placeholder="Re-type new password" />
      </Form.Item>

      <Form.Item name="token" initialValue={inputToken} noStyle>
        <Input type="hidden" />
      </Form.Item>

      <Form.Item>
        <Button
          type="primary"
          block={true}
          htmlType="submit"
          loading={submitting}
        >
          {submitText}
        </Button>
      </Form.Item>
      {error && <Alert message="Error" description={error} type="error" />}
    </Form>
  );
}

function SignUpForm() {
  const [error, setError] = React.useState<string | undefined>(undefined);
  const [submitting, setSubmitting] = React.useState(false);

  const {search} = useLocation();
  const params = queryString.parse(search);

  var email = undefined;
  var invitationCode = undefined;
  if (params && params.code) {
    invitationCode = params.code;
    try {
      email = JSON.parse(base64.decode(invitationCode)).email;
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <Form<FormState>
      size={'large'}
      name="signup"
      initialValues={{username: email, code: invitationCode}}
      onFinish={FormAction(routes.signup_auth, setError, setSubmitting)}
    >
      <Form.Item
        name="username"
        rules={[
          {
            required: true,
            message: 'Please input your email',
          },
          {
            type: 'email',
            message: 'The input is not valid email!',
          },
        ]}
      >
        <Input placeholder="Email" />
      </Form.Item>

      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: 'Please input your password',
          },
          {
            min: passwordMinLen,
            message: 'Password must have a minimum length of 12',
          },
          {
            pattern: passwordRegex,
            message:
              'Only Alphanumeric characters and ~!@#$%^&*_+=- symbols can be used in password',
          },
        ]}
        hasFeedback
      >
        <Input.Password placeholder="Password" />
      </Form.Item>

      <Form.Item
        name="code"
        rules={[{required: true, message: 'Please input your invitation code'}]}
      >
        <Input placeholder="Invitation Code" />
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          block={true}
          htmlType="submit"
          loading={submitting}
        >
          Sign Up
        </Button>
      </Form.Item>
      {error && <Alert message="Error" description={error} type="error" />}
    </Form>
  );
}

function SignUpCard() {
  return (
    <Card className={styles.login_card}>
      <div className={styles.login_card_row}>
        <Space
          className={styles.login_card_column}
          direction="vertical"
          size="large"
        >
          <Typography.Title level={4}>Sign Up</Typography.Title>
          <SignUpForm />
          <Text>
            {' '}
            Already have an account? Please{' '}
            <Link
              to={`${routes.login}/${window.location.search}${window.location.hash}`}
            >
              {' '}
              log in{' '}
            </Link>{' '}
            here.
          </Text>
        </Space>
      </div>
    </Card>
  );
}

export const App = () => {
  // TODO(lit): use relay to clear sessions for logout
  const Reload = () => {
    React.useEffect(() => {
      window.location.reload();
    });
    return null;
  };

  return (
    <Router>
      <SingleColumnLayout title={'Conversions API Gateway'}>
        <Switch>
          <Route exact path={routes.login}>
            <LoginCard />
          </Route>
          <Route exact path={routes.signup}>
            <SignUpCard />
          </Route>
          <Route exact path={routes.verify}>
            <VerifyCard />
          </Route>
          <Route exact path={routes.forgot_password}>
            <ForgotPasswordCard />
          </Route>
          <Route exact path={routes.logout}>
            <Reload />
          </Route>
        </Switch>
      </SingleColumnLayout>
    </Router>
  );
};
